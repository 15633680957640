import types from "rdx/modules/projects/types";
import createAction from "rdx/utils/createAction";

export default {
  getMentorsList: (payload) => createAction(types.GET_MENTORS_LIST_REQUEST, payload),
  setMentorsList: (payload) => createAction(types.SET_MENTORS_LIST, payload),
  setPowurPairList: (payload) => createAction(types.SET_POWUR_PAIR_LIST, payload),
  resetMentorsList: (payload) => createAction(types.RESET_MENTORS_LIST, payload),
  resetPowurPairList: (payload) => createAction(types.RESET_POWUR_PAIR_LIST, payload),
  resetProjectsList: (payload) => createAction(types.RESET_PROJECTS_LIST, payload),
  selectMentor: (payload) => createAction(types.SELECT_MENTOR_REQUEST, payload),
  removeMentor: (payload) => createAction(types.REMOVE_MENTOR_REQUEST, payload),
  removeAmbassador: (payload) => createAction(types.REMOVE_AMBASSADOR_REQUEST, payload),
  getProviderLeadDetails: (payload) => createAction(types.GET_PROVIDER_LEAD_DETAILS_REQUEST, payload),
  setProjectDetails: (payload) => createAction(types.SET_PROJECT_DETAILS, payload),
  setProjectLeadDetails: (payload) => createAction(types.SET_LEAD_PROJECT_DETAILS, payload),
  getProviderLeadsList: (payload) => createAction(types.GET_PROVIDER_LEADS_LIST_REQUEST, payload),
  setProviderLeadsList: (payload) => createAction(types.SET_PROVIDER_LEADS_LIST, payload),
  setProviderLeadsFilters: (payload) => createAction(types.SET_PROVIDER_LEADS_LIST_FILTERS, payload),
  setLeadsEventCounts: (payload) => createAction(types.SET_LEADS_EVENT_COUNTS, payload),
  updateProviderLead: (payload) => createAction(types.UPDATE_PROVIDER_LEAD, payload),
  updateProjectLeadInfo: (payload) => createAction(types.UPDATE_PROJECT_LEAD_INFORMATION_REQUEST, payload),
  setProjectsListAutoComplete: (payload) => createAction(types.SET_PROJECTS_AUTOCOMPLETE_SUGGESTIONS, payload),

  getProjectGeocode: (payload) => createAction(types.GET_PROJECT_ADDRESS_GEOCODE, payload),
  setProjectGeocode: (payload) => createAction(types.SET_PROJECT_ADDRESS_GEOCODE, payload),

  getNotes: (payload) => createAction(types.GET_NOTES_REQUEST, payload),
  setNotes: (payload) => createAction(types.SET_NOTES, payload),
  postNewNote: (payload) => createAction(types.POST_NEW_NOTE_REQUEST, payload),
  deleteNote: (payload) => createAction(types.DELETE_NOTE_REQUEST, payload),
  editNote: (payload) => createAction(types.EDIT_NOTE_REQUEST, payload),

  getAssets: (payload) => createAction(types.GET_ASSETS_REQUEST, payload),
  setAssets: (payload) => createAction(types.SET_ASSETS, payload),
  postNewAsset: (payload) => createAction(types.POST_NEW_ASSET_REQUEST, payload),
  deleteAsset: (payload) => createAction(types.DELETE_ASSET_REQUEST, payload),

  getProjectProposals: (payload) => createAction(types.GET_PROJECT_PROPOSALS_REQUEST, payload),
  setProjectProposals: (payload) => createAction(types.SET_PROJECT_PROPOSALS, payload),
  setVisionProposalCount: (payload) => createAction(types.SET_VISION_PROPOSAL_COUNT, payload),
  getVisionProposalCount: (payload) => createAction(types.GET_VISION_PROPOSAL_COUNT, payload),
  requestProposal: (payload) => createAction(types.REQUEST_PROPOSAL, payload),
  requestVisionProposal: (payload) => createAction(types.REQUEST_VISION_PROPOSAL, payload),
  setCreateVisionReqSuccessful: (payload) => createAction(types.SET_CREATE_VISION_REQ_SUCCESS, payload),
  requestRevision: (payload) => createAction(types.REQUEST_PROPOSAL_REVISION, payload),
  requestVisionRevision: (payload) => createAction(types.REQUEST_VISION_PROPOSAL_REVISION, payload),

  resetProjectDetails: (payload) => createAction(types.RESET_PROJECT_DETAILS, payload),

  closeProject: (payload) => createAction(types.CLOSE_PROJECT_REQUEST, payload),
  reopenProject: (payload) => createAction(types.REOPEN_PROJECT_REQUEST, payload),

  uploadCoordinators: (payload) => createAction(types.UPLOAD_COORDINATOR_CSV_REQUEST, payload),
  getProjectsAutoComplete: (payload) => createAction(types.GET_PROJECT_LEADS_AUTOCOMPLETE_REQUEST, payload),
  setProjectLeadsAutoComplete: (payload) => createAction(types.SET_PROJECT_LEADS_AUTOCOMPLETE, payload),
  updateProjectBadgeCount: (payload) => createAction(types.UPDATE_PROJECT_BADGE_COUNT, payload),
  invalidateProposalCache: (payload) => createAction(types.INVALIDATE_PROPOSAL_CACHE, payload),

  inviteToMyPowur: (payload) => createAction(types.INVITE_TO_MY_POWUR, payload),

  requestProposalUnlock: (payload) => createAction(types.REQUEST_PROPOSAL_UNLOCK, payload),

  createSiteSurveySchedule: (payload) => createAction(types.CREATE_SITE_SURVEY_SCHEDULE, payload),

  setSiteSurveySchedule: (payload) => createAction(types.SET_SITE_SURVEY_SCHEDULE, payload),

  getSiteSurveySchedule: (payload) => createAction(types.GET_SITE_SURVEY_SCHEDULE, payload),

  setProposalSettings: (payload) => createAction(types.SET_PROPOSAL_SETTINGS, payload),

  updateProposalSettings: (payload) => createAction(types.UPDATE_PROPOSAL_SETTINGS, payload),

  updatePreSalesMilestones: (payload) => createAction(types.UPDATE_PRE_SALES_MILESTONES, payload),

  getProposalSettings: (payload) => createAction(types.GET_PROPOSAL_SETTINGS, payload),

  updateProviderLeadUsers: (payload) => createAction(types.UPDATE_PROVIDER_LEAD_USERS, payload),

  setProjectPayTerms: (payload) => createAction(types.SET_PROJECT_PAY_TERMS, payload),

  getProjectPayTerms: (payload) => createAction(types.GET_PROJECT_PAY_TERMS, payload),

  createRoofingProposal: (payload) => createAction(types.CREATE_ROOFING_PROPOSALS, payload),

  getRoofingProposals: (payload) => createAction(types.GET_ROOFING_PROPOSALS_REQUEST, payload),

  getRoofingProposalDetails: (payload) => createAction(types.GET_ROOFING_PROPOSAL_DETAILS, payload),

  setRoofingProposals: (payload) => createAction(types.SET_ROOFING_PROPOSALS, payload),

  setRoofingProposalDetails: (payload) => createAction(types.SET_PROJECT_ROOFING_PROPOSAL_DETAILS, payload),

  setLatestRoofingProposal: (payload) => createAction(types.SET_LATEST_ROOFING_PROPOSAL, payload),

  setInterestRatesDrawerOpen: () => createAction(types.SET_INTEREST_RATES_DRAWER_OPEN),
  setInterestRatesDrawerClosed: () => createAction(types.SET_INTEREST_RATES_DRAWER_CLOSED),
  setProjectInterestRates: (payload) => createAction(types.SET_PROJECT_INTEREST_RATES, payload),
  getProjectInterestRates: (payload) => createAction(types.GET_PROJECT_INTEREST_RATES, payload),

  inviteToPowurSite: (payload) => createAction(types.INVITE_TO_POWUR_SITE, payload),

  getProjectsCsv: (payload) => createAction(types.GET_PROJECTS_CSV, payload),
  setProjectsCsv: (payload) => createAction(types.SET_PROJECTS_CSV, payload),

  getProviderLeadsCsv: (payload) => createAction(types.GET_PROVIDER_LEADS_CSV, payload),
  setProviderLeadsCsv: (payload) => createAction(types.SET_PROVIDER_LEADS_CSV, payload),

  setProposalRequestModalVisible: (payload) => createAction(types.SET_REQUEST_MODAL_VISIBLE, payload),
  getProposalRequestModalVisible: (payload) => createAction(types.GET_REQUEST_MODAL_VISIBLE, payload),

  setRoofingModalVisible: (payload) => createAction(types.SET_ROOFING_MODAL_VISIBLE, payload),
  getRoofingModalVisible: (payload) => createAction(types.GET_ROOFING_MODAL_VISIBLE, payload),

  setRoofingModalTitle: (payload) => createAction(types.SET_ROOFING_MODAL_TITLE, payload),
  getRoofingModalTitle: (payload) => createAction(types.GET_ROOFING_MODAL_TITLE, payload),

  getRoofingNonReqReason: (payload) => createAction(types.GET_ROOFING_NON_REQ_REASON, payload),
  setRoofingNonReqReason: (payload) => createAction(types.SET_ROOFING_NON_REQ_REASON, payload),

  getHoveredProject: (payload) => createAction(types.GET_HOVERED_PROJECT, payload),
  setHoveredProject: (payload) => createAction(types.SET_HOVERED_PROJECT, payload),

  getProjectsCsvReport: (payload) => createAction(types.GET_PROJECTS_CSV_REPORT, payload),

  getProviderLeadsCsvReport: (payload) => createAction(types.GET_PROVIDER_LEADS_CSV_REPORT, payload),

  getProviderLeadsKanban: (payload) => createAction(types.GET_PROVIDER_LEADS_KANBAN_REQUEST, payload),
  setProviderLeadsKanban: (payload) => createAction(types.SET_PROVIDER_LEADS_KANBAN, payload),
  setProviderLeadStageInKanban: (payload) => createAction(types.SET_PROVIDER_LEAD_STAGE_IN_KANBAN, payload),
  updateCrmActivityInList: (payload) => createAction(types.SET_CRM_ACTIVITY_IN_LIST, payload),
  addCrmActivityToKanban: (payload) => createAction(types.ADD_CRM_ACTIVITY_TO_KANBAN, payload),
  addCrmActivityToList: (payload) => createAction(types.ADD_CRM_ACTIVITY_TO_LIST, payload),
  removeCompleteCrmActivities: (payload) => createAction(types.REMOVE_COMPLETE_CRM_ACTIVITIES, payload),
  addCrmActivityToDetail: (payload) => createAction(types.SET_CRM_ACTIVITY_IN_DETAIL, payload),
  addProjectCrmActivity: (payload) => createAction(types.ADD_PROJECT_CRM_ACTIVITY, payload),
  updateProjectCrmActivity: (payload) => createAction(types.UPDATE_PROJECT_CRM_ACTIVITY, payload),
  updateProjectCrmActivities: (payload) => createAction(types.UPDATE_PROJECT_CRM_ACTIVITIES, payload),
  setCrmActivityLog: (payload) => createAction(types.SET_CRM_ACTIVITY_LOG, payload),
  downloadAssets: (payload) => createAction(types.DOWNLOAD_ASSETS, payload),
  setContractTabComponent: (payload) => createAction(types.SET_CONTRACT_TAB_COMPONENT, payload),

  getContractDetails: (payload) => createAction(types.GET_CONTRACT_DETAILS_REQUEST, payload),
  setContractDetails: (payload) => createAction(types.SET_CONTRACT_DETAILS, payload),
  setContractTabVisible: (payload) => createAction(types.SET_CONTRACT_TAB_VISIBLE, payload),
  setDashboardTabVisible: (payload) => createAction(types.SET_DASHBOARD_TAB_VISIBLE, payload),
  getKpiData: (payload) => createAction(types.GET_KPI_DATA, payload),
  setKpiData: (payload) => createAction(types.SET_KPI_DATA, payload),
  setContractAsSoldDetails: (payload) => createAction(types.SET_CONTRACT_AS_SOLD_DETAILS, payload),
  sendSalesDesignConfirmation: (payload) => createAction(types.SEND_SALES_DESIGN_CONFIRMATION, payload),
  setProjectChangeRequestVisible: (payload) => createAction(types.SET_PROJECT_CHANGE_REQUEST_VISIBLE, payload),
  setCommissionTabVisible: (payload) => createAction(types.SET_COMMISSION_TAB_VISIBLE, payload),
  setTabsAsLeadGenerator: (payload) => createAction(types.SET_TABS_AS_LEAD_GENERATOR, payload),

  getProjectLookupOptions: (payload) => createAction(types.GET_PROJECT_LOOKUP_OPTIONS, payload),
  setProjectLookupOptions: (payload) => createAction(types.SET_PROJECT_LOOKUP_OPTIONS, payload),
  postSellerChangeOrder: (payload) => createAction(types.POST_SELLER_CHANGE_ORDER, payload),

  getFundingProposals: (payload) => createAction(types.GET_FUNDING_PROPOSALS_REQUEST, payload),
  getFundingProposalDetails: (payload) => createAction(types.GET_FUNDING_PROPOSAL_DETAILS, payload),
  setFundingProposals: (payload) => createAction(types.SET_FUNDING_PROPOSALS, payload),
  setFundingProposalDetails: (payload) => createAction(types.SET_PROJECT_FUNDING_PROPOSAL_DETAILS, payload),
  clearFundingProposalDetails: () => createAction(types.CLEAR_PROJECT_FUNDING_PROPOSAL_DETAILS),
  setFundingProposalRequestable: (payload) => createAction(types.SET_FUNDING_PROPOSAL_REQUESTABLE, payload),

  setLatestIndexRoute: (payload) => createAction(types.SET_LATEST_INDEX_ROUTE, payload),
  setCompareContractId: (payload) => createAction(types.SET_COMPARE_CONTRACT_ID, payload),

  setContractVersion: (payload) => createAction(types.SET_CONTRACT_VERSION, payload),
  setHideProjectChangeBanner: (payload) => createAction(types.SET_HIDE_PROJECT_CHANGE_BANNER, payload),

  createTpoPricingWorksheet: (payload) => createAction(types.CREATE_TPO_PRICING_WORKSHEET, payload),
  setTpoPricingWorksheet: (payload) => createAction(types.SET_TPO_PRICING_WORKSHEET, payload),
  getTpoPricingWorksheets: (payload) => createAction(types.GET_TPO_PRICING_WORKSHEETS, payload),
  setTpoPricingWorksheets: (payload) => createAction(types.SET_TPO_PRICING_WORKSHEETS, payload),
  setIsTpoPricingWorksheetRequested: (payload) => createAction(types.SET_IS_TPO_PRICING_WORKSHEET_REQUESTED, payload),

  setChangeOrderBlockerComplete: () => createAction(types.SET_CHANGE_ORDER_BLOCKER_COMPLETE),
  removeChangeOrderBlockerFromModal: () => createAction(types.REMOVE_CO_BLOCKER_FROM_MODAL),
  // INSERTION_PT (for script, do not remove)
};
