import React, { useState, useEffect, CSSProperties } from "react";
import { useLocation, useParams } from "react-router-dom";

// External
import { parse } from "query-string";
import { GridRowParams, useGridApiRef } from "@mui/x-data-grid-pro";
import type { GridColumns, GridRowModel, GridSortModel, GridSortItem, DataGridProProps } from "@mui/x-data-grid-pro";
import { Spin } from "antd";
import { css } from "@mui/material";
import { v4 as uuid } from "uuid";

// Hooks
import useWindowResize from "hooks/useWindowResize";
import { UseNavigation } from "hooks/useNavigation";

import { toSnakeCase, formatNumberWithCommas } from "lib/helpers";

// Components
import { NoResultsOverlay } from "components/Icons/NoResultsOverlay/NoResultsOverlay";
import SearchFilterCSV from "components/Pagination/SearchFilterPagination/SearchFilterCSV";
import { PlusOutlined, DownloadIcon, DownCaretFilled, UpCaretFilled } from "components/Icons";
import { cvar } from "styles";
import { FiltersT } from "components/Pagination/SearchFilterPagination/NewFilters/types";
import { theme } from "styles/themes";
import { PrimaryButton } from "components/mui-styled-components";

// StylesgetCsvReport
import * as S from "./styles";

type BasicTablePropsT<TData extends GridRowModel> = {
  rows: TData[];
  columns: GridColumns;
  sortItems?: FiltersT;
  getCsv?: (search: Record<string, unknown>) => void;
  getCsvReport?: ({ reportId }: { reportId: number }) => void;
  selectedCsv?: Record<string, number | string>;
  navigate?: ReturnType<UseNavigation>["navigate"];
  override?: ReturnType<UseNavigation>["override"];
  totalResultCount?: number;
  isLoading?: boolean;
  getData?: (payload?: Record<string, unknown>) => void;
  resetData?: () => void;
  onRowClick?: (params: GridRowParams<TData>) => void;
  defaultSort?: GridSortItem;
  rightSideComponent?: React.ReactElement;
  isMobile?: boolean; // after all tables have the same design could be removed
  hideFilterBar?: boolean;
  hideColumnHeaders?: boolean;
  hideBottomPadding?: boolean;
  noXScroll?: boolean;
  containerStyles?: CSSProperties;
  hideCSV?: boolean;
  handleFilterBarButtonClick?: () => void;
  showFilterBarButton?: boolean;
  filterBarButtonText?: string;
  rowHeight?: number;
} & DataGridProProps;

const BasicTable = <T extends Record<string, unknown>>({
  rows,
  columns,
  getCsv,
  getCsvReport,
  selectedCsv,
  sortItems,
  navigate,
  override,
  totalResultCount,
  isLoading,
  getData,
  resetData,
  onRowClick,
  defaultSort,
  rightSideComponent,
  isMobile,
  showFilterBarButton,
  handleFilterBarButtonClick,
  filterBarButtonText,
  hideFilterBar = false,
  hideColumnHeaders = false,
  hideBottomPadding = false,
  containerStyles = {},
  noXScroll = false,
  hideCSV = false,
  rowHeight = 78,
}: BasicTablePropsT<T>) => {
  const { search } = useLocation();
  const params: { tab: string } = useParams();
  const { width } = useWindowResize();
  const apiRef = useGridApiRef();
  const tablet = width <= 768;

  const [csvPending, setCsvPending] = useState(false);
  const [csvIntervalId, setCsvIntervalId] = useState<number | null>(null);
  const [sortModel, setSortModel] = useState<GridSortModel>(defaultSort ? [defaultSort] : []);
  const showPrimaryBtn = showFilterBarButton && filterBarButtonText && handleFilterBarButtonClick;

  const csvText = () => {
    if (tablet) return "CSV";

    return csvPending ? "Preparing CSV" : "Export CSV";
  };

  const getReport = (interval: number, reportId: number) => {
    const intervalId = window.setInterval(() => {
      if (reportId && getCsvReport) getCsvReport({ reportId });
    }, interval);

    setCsvIntervalId(intervalId);
  };

  useEffect(() => {
    if (selectedCsv?.url && csvPending) {
      setCsvPending(false);
      clearIntervalIds();
      window.open(selectedCsv.url as string, "_blank");
    } else if (selectedCsv?.id && !csvIntervalId) {
      getReport(2000, selectedCsv.id as number);
    }
  }, [selectedCsv]); // eslint-disable-line react-hooks/exhaustive-deps

  const createCsv = () => {
    if (getCsv) getCsv(parse(search));
    setCsvPending(true);
  };

  const clearIntervalIds = () => {
    if (csvIntervalId) {
      clearInterval(csvIntervalId);
      setCsvIntervalId(null);
    }
  };

  const columnHeaderHeight = () => {
    const gapBetweenHeaderAndRows = 8;
    if (isMobile || hideColumnHeaders) {
      return gapBetweenHeaderAndRows;
    }
    return 38 + gapBetweenHeaderAndRows;
  };

  return (
    <S.Container isMobile={isMobile} style={containerStyles}>
      {!hideFilterBar && (
        <S.SearchFilterContainer isMobile={isMobile}>
          <SearchFilterCSV sortItems={sortItems} navigate={navigate} override={override} noCSV isLoading={isLoading} />
          <S.AlignCenter>
            {rightSideComponent}
            {!!totalResultCount && <S.Results>{formatNumberWithCommas(totalResultCount || 0)} Results</S.Results>}
            {!hideCSV && (
              <S.CSVButton onClick={createCsv} variant="contained" disabled={csvPending} isActive={csvPending}>
                {!tablet && <DownloadIcon fill={csvPending ? theme.colors.white : theme.colors["blue-ribbon"]} />}
                <span css={csvPending ? S.loadingCsv : S.csvText}>{csvText()}</span>
              </S.CSVButton>
            )}

            {showPrimaryBtn && (
              <PrimaryButton
                name="filter-bar-button"
                gradient
                css={css`
                  height: 28px;
                  gap: 8px;
                  margin-left: 9px;
                `}
                onClick={handleFilterBarButtonClick}
              >
                <S.StyledContentContainer>
                  <PlusOutlined
                    width="18"
                    height="18"
                    fill={`linear-gradient(270.54deg, ${cvar("picton-blue")} 49.88%, ${cvar("blue-ribbon")} 135.73%)`}
                    innerFill={cvar("white")}
                  />
                  {filterBarButtonText}
                </S.StyledContentContainer>
              </PrimaryButton>
            )}
          </S.AlignCenter>
        </S.SearchFilterContainer>
      )}

      <S.StyledDataGridPro
        isMobile={isMobile}
        hideColumnHeaders={hideColumnHeaders}
        hideBottomPadding={hideBottomPadding}
        noXScroll={noXScroll}
        onRowClick={(rowParams) => {
          if (onRowClick) {
            onRowClick(rowParams);
          }
        }}
        getRowId={(row) => row.projectId || row.id || uuid()}
        disableColumnMenu
        headerHeight={columnHeaderHeight()}
        rowHeight={isMobile ? 106 : rowHeight}
        apiRef={apiRef}
        loading={isLoading}
        sortingMode="server"
        sortModel={sortModel}
        onSortModelChange={(e) => {
          setSortModel(e);
          if (resetData) {
            resetData();
          }
          if (navigate) {
            const newSearch = { sort: e[0] ? `${toSnakeCase(e[0].field)}_${e[0].sort}` : null };
            navigate(newSearch);
          }
        }}
        addLoadingSpace={isLoading}
        onRowsScrollEnd={() => {
          const { top } = apiRef.current.getScrollPosition();
          if (getData && top !== 0) {
            getData({ ...parse(search), tab: toSnakeCase(params.tab) });
          }
        }}
        components={{
          LoadingOverlay: () => (
            <S.LoadingOverlay firstLoading={rows.length === 0}>
              <Spin />
            </S.LoadingOverlay>
          ),
          NoRowsOverlay: () => {
            return <NoResultsOverlay />;
          },
          NoResultsOverlay: () => {
            return <NoResultsOverlay />;
          },
          ColumnUnsortedIcon: () => {
            return <DownCaretFilled fill={cvar("dark-periwinkle")} />;
          },
          ColumnSortedAscendingIcon: () => {
            return <UpCaretFilled fill={cvar("blue-ribbon")} />;
          },
          ColumnSortedDescendingIcon: () => {
            return <DownCaretFilled fill={cvar("blue-ribbon")} />;
          },
        }}
        columns={columns}
        rows={rows}
      />
      {isMobile && showPrimaryBtn && (
        <S.MobileFixedFooter>
          <S.StyledMobPrimaryButton name="add-lead" gradient onClick={handleFilterBarButtonClick}>
            <S.StyledContentContainer>{filterBarButtonText}</S.StyledContentContainer>
          </S.StyledMobPrimaryButton>
        </S.MobileFixedFooter>
      )}
    </S.Container>
  );
};

export default BasicTable;
